<template>
  <v-app>
        <transition>
          <router-view></router-view>
        </transition>
  </v-app>

</template>
<script>
export default {
  name: "Layout",
  data() {
    return {
      overlay: false,
    };
  },
};
</script>
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center !important;
    color: #2c3e50;
    padding: 0% !important ;
}
</style>